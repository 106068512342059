<template>
  <div class="" v-if="contract">
    <el-form :model="contract" :rules="contractRules" ref="form">
      <TopBarTitleComponent title="Contratos" :showBackIcon="true">
        <template slot="right-area">
          <div class="d-flex justify-content-end align-items-center">
            <el-dropdown
              @command="handleCommand"
              class="dropdown-theme-gray-outline d-flex align-items-center mr-4"
            >
              <span class="el-dropdown-link">
                <span class="ml-2">Opciones</span>
                <i class="el-icon-arrow-down el-icon--right mr-2"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="contract-send">
                  Enviar contrato</el-dropdown-item
                >
                <el-dropdown-item command="contract-download">
                  Descargar PDF</el-dropdown-item
                >
                <el-dropdown-item command="contract-delete">
                  Eliminar</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <ButtonPrimaryComponent
              title="Guardar"
              @onSaveClick="onSaveClick"
            />
          </div>
        </template>
      </TopBarTitleComponent>
      <b-container fluid class="pb-4 px-lg-4">
        <b-row>
          <b-col xs="12" sm="12">
            <div class="card">
              <div class="card-body">
                <el-form-item prop="name" class="mb-4">
                  <el-input
                    placeholder="Nombre del contracto"
                    v-model="contract.name"
                    autocomplete="none"
                    id="input-text-top-bar"
                    class="h2 font-semi-bold"
                  ></el-input>
                </el-form-item>

                <EditorWysiwygComponent
                  @onEditorChange="onEditorChange"
                  :setText.sync="contract.description"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </el-form>
  </div>
</template>

<script>
import TopBarTitleComponent from "../../components/TopBarTitle";
import ButtonPrimaryComponent from "../../components/ButtonPrimary";
import EditorWysiwygComponent from "../../components/EditorWysiwyg";

import ContractService from "../services/ContractService";
import Validate from "@/assets/validate";
import { downloadFile } from "@/assets/utils/files";

export default {
  name: "ContractEditPage",
  data() {
    return {
      contract: null,
      contractRules: {
        name: [Validate.rules.required()]
      }
    };
  },
  methods: {
    onSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await ContractService.updateContractId(
              this.contract
            ).finally(() => {
              this.$store.dispatch("toggleRequestLoading");
            });
            const { success, message } = response.data;
            if (success) {
              this.$notifications.success({
                message
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    async onConfirmDelete() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await ContractService.deleteContractId(
          this.contract
        ).finally(() => {
          this.$store.dispatch("toggleRequestLoading");
        });
        const { success, message } = response.data;
        if (success) {
          this.$router.push({ name: "agreements" });
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    async getContractId(contractId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await ContractService.getContractId(contractId).finally(
          () => {
            this.$store.dispatch("toggleRequestLoading");
          }
        );
        const { success, data } = response.data;
        if (success) {
          this.contract = data;
        }
      } catch (error) {
        return false;
      }
    },
    handleCommand(command) {
      switch (command) {
        case "contract-send":
          // this.visibleQuoteSend = true;
          break;
        case "contract-download":
          this.onDownloadContract();
          break;
        case "contract-delete":
          this.onConfirmDelete();
          break;
      }
    },
    onEditorChange({ text }) {
      this.contract.description = text;
    },
    async onDownloadContract() {
      const { id: contractId, name: contractName } = this.contract;
      try {
        this.$store.dispatch("toggleRequestLoading");
        let response = await ContractService.downloadContractId(
          contractId
        ).finally(() => {
          this.$store.dispatch("toggleRequestLoading");
        });
        const { data } = response;
        if (response) {
          downloadFile(data, contractName || "Contrato");
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    TopBarTitleComponent,
    ButtonPrimaryComponent,
    EditorWysiwygComponent
  },
  mounted() {
    const { id: contractId } = this.$route.params;
    if (contractId) this.getContractId(contractId);
  }
};
</script>

<style lang="scss" scoped></style>
